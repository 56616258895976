<!--
 * @Author: xr
 * @Date: 2022-02-21 11:58:59
 * @LastEditors: xr
 * @LastEditTime: 2022-03-09 11:19:04
 * @FilePath: \postStation-office\src\views\professionalDetail.vue
-->
<template>
  <div class="main">
    <div class="main_box">
        <a-row>
          <a-col :span="7">
            <div class="right-side-assembly">
              <find-demand class="find-item" title="找项目" :technicalProjectContent="technicalProjectContent" />
              <find-demand class="find-demand" title="找需求" :technicalProjectContent="technicalProjectContent"/>
            </div>
          </a-col>
          <a-col :span="17" class="description">
            <a-row class="sub-component-background-color" style="min-height: 75.9vh;padding: 0px 10px">
              <div class="info-title">专家信息登记表</div>
              <!-- 项目信息 -->
              <a-descriptions layout="horizontal" bordered size="small">
                <a-descriptions-item label="姓名" span="2">
                  {{people.name}}
                </a-descriptions-item>
                <a-descriptions-item label="性别">
                  {{people.sex_dictText}}
                </a-descriptions-item>
                <a-descriptions-item label="工作单位" span="3">
                  {{people.workUnit}}
                </a-descriptions-item>
                <a-descriptions-item label="毕业院校" span="3">
                  {{people.graduateSchool}}
                </a-descriptions-item>
                <a-descriptions-item  label="所学专业" span="3">
                  {{people.major}}
                </a-descriptions-item>
                <a-descriptions-item label="主要研究领域">
                  {{people.mainResearchAreas_dictText}}
                </a-descriptions-item>
              </a-descriptions>
            </a-row>
          </a-col>
        </a-row>
    </div>
  </div>
</template>
<script>
import FindDemand from '@comp/findDemand'
import { getProfessionalById, getDict } from '@/api/index'
export default {
  name: 'ProfessionalDetail',
  components: {
    FindDemand
  },
  data() {
    return {
      // 专家信息
      people: {},
      technicalProjectContent: []
    }
  },
  activated() {
    // 获取数据
    getProfessionalById({ id: this.$route.query.id }).then(res => {
      this.people = res.result
    })
  },
  created() {
    getDict('application_field').then(res => {
      this.technicalProjectContent = res.result
    })
  }
}
</script>
<style lang="scss" scoped>
$border: #85C0EE;
.description {
  min-height: 80vh;
  padding: 0px 10px;
  .info-title{
    text-align: center;
    font-size: 20px;
    margin-bottom: 5px;
    font-family: "宋体";
    font-weight: bold;
  }
  ::v-deep .ant-descriptions-item-content{
    border-right:1px solid  $border;
  }
  ::v-deep .ant-descriptions-bordered .ant-descriptions-item-label{
    border-right:1px solid  $border;
    background: none;
    font-weight: bold;
  }
  ::v-deep .ant-descriptions-bordered .ant-descriptions-row{
    border-bottom: 1px solid $border;
  }
  ::v-deep .ant-descriptions-view{
    border-top: 4px solid #047dd2;
    border-right: 0px;
    border-bottom: 0px;
    border-left: 1px solid $border;
  }
}

@import "../assets/css/newsInformation.css";
</style>
